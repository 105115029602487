<template>
    <div>
        <h4 class="mb-5">{{ $t('income_and_expenses')}}</h4>
        <LineChartGenerator
                :chart-options="chartOptions"
                :chart-data="chartData"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="width"
                :height="height"
        />

    </div>
</template>

<script>
    import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        Legend,
        LineElement,
        LinearScale,
        CategoryScale,
        PointElement
    } from 'chart.js';

    ChartJS.register(
        Title,
        Tooltip,
        Legend,
        LineElement,
        LinearScale,
        CategoryScale,
        PointElement
    );


    export default {
        name: 'profitAndLoss',
        components: {
            LineChartGenerator
        },
        props: {
            dataProfitLoss: null,
            currencyName: null,
            chartId: {
                type: String,
                default: 'line-chart'
            },
            datasetIdKey: {
                type: String,
                default: 'label'
            },
            width: {
                type: Number,
                default: 400
            },
            height: {
                type: Number,
                default: 400
            },
            cssClasses: {
                default: '',
                type: String
            },
            styles: {
                type: Object,
                default: () => {}
            },
            plugins: {
                type: Array,
                default: () => []
            }
        },
        watch: {
            dataProfitLoss: function (val) {
                if(val){
                    this.chartData.labels = val.labels;
                    this.chartData.datasets[0].data = val.income;
                    this.chartData.datasets[1].data = val.expenses;
                }
            },
        },
        data() {
            return {
                chartData: {
                    labels: [],
                    datasets: [
                        {
                            label: this.$t('income'),
                            backgroundColor: '#f87979',
                            data: []
                        },
                        {
                            label: this.$t('expense'),
                            backgroundColor: '#41B883',
                            data: []
                        },

                    ]
                },
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            }
        },
        mounted() {
            this.chartData.labels = this.dataProfitLoss.labels;
            this.chartData.datasets[0].data = this.dataProfitLoss.income;
            this.chartData.datasets[1].data = this.dataProfitLoss.expenses;
        },
    }
</script>

<style scoped>

</style>

<!--<template>-->
<!--    <div>-->
<!--        <h4 class="mb-5">{{ $t('income_and_expenses')}}</h4>-->
<!--        <div id="chart">-->
<!--            <apexchart type="line" ref="apexchart" height="350" :options="chartOptions" :series="series" ></apexchart>-->
<!--        </div>-->

<!--    </div>-->
<!--</template>-->
<!--<script>-->
<!--    import VueApexCharts from 'vue-apexcharts';-->

<!--    export default {-->
<!--        name: 'profitAndLoss',-->
<!--        components: {-->
<!--            'apexchart': VueApexCharts,-->
<!--        },-->
<!--        props: {-->
<!--            dataProfitLoss: null,-->
<!--            currencyName: null,-->
<!--        },-->

<!--        data() {-->
<!--            return {-->
<!--                series: [{-->
<!--                    name: this.$t('income'),-->
<!--                    type: 'column',-->
<!--                    data: []-->
<!--                }, {-->
<!--                    name: this.$t('expense'),-->
<!--                    type: 'column',-->
<!--                    data: []-->
<!--                }, {-->
<!--                    name: this.$t('profit'),-->
<!--                    type: 'line',-->
<!--                    data: [20, 29]-->
<!--                }],-->

<!--                chartOptions: {-->
<!--                    chart: {-->
<!--                        animations: {-->
<!--                            enabled: false,-->
<!--                        }-->
<!--                    },-->
<!--                    dataLabels: {-->
<!--                        enabled: true-->
<!--                    },-->
<!--                    stroke: {-->
<!--                        width: [1, 1, 4]-->
<!--                    },-->
<!--                    // title: {-->
<!--                    //     text: 'XYZ - Stock Analysis (2009 - 2016)',-->
<!--                    //     align: 'center',-->
<!--                    //     offsetX: 110-->
<!--                    // },-->
<!--                    xaxis: {-->
<!--                        categories: ['s','ss'],-->
<!--                    },-->
<!--                    yaxis: [-->
<!--                        {-->
<!--                            axisTicks: {-->
<!--                                show: true,-->
<!--                            },-->
<!--                            axisBorder: {-->
<!--                                show: true,-->
<!--                                color: '#008FFB'-->
<!--                            },-->
<!--                            labels: {-->
<!--                                style: {-->
<!--                                    colors: '#008FFB',-->
<!--                                }-->
<!--                            },-->
<!--                            title: {-->
<!--                                text: "Income (thousand crores)",-->
<!--                                style: {-->
<!--                                    color: '#008FFB',-->
<!--                                }-->
<!--                            },-->
<!--                            tooltip: {-->
<!--                                enabled: true-->
<!--                            }-->
<!--                        },-->
<!--                        {-->
<!--                            seriesName: 'income',-->
<!--                            opposite: true,-->
<!--                            axisTicks: {-->
<!--                                show: true,-->
<!--                            },-->
<!--                            axisBorder: {-->
<!--                                show: true,-->
<!--                                color: '#00E396'-->
<!--                            },-->
<!--                            labels: {-->
<!--                                style: {-->
<!--                                    colors: '#00E396',-->
<!--                                }-->
<!--                            },-->
<!--                            title: {-->
<!--                                text: this.$t('income'),-->
<!--                                style: {-->
<!--                                    color: '#00E396',-->
<!--                                }-->
<!--                            },-->
<!--                        },-->
<!--                        {-->
<!--                            seriesName: 'expense',-->
<!--                            opposite: true,-->
<!--                            axisTicks: {-->
<!--                                show: true,-->
<!--                            },-->
<!--                            axisBorder: {-->
<!--                                show: true,-->
<!--                                color: '#FEB019'-->
<!--                            },-->
<!--                            labels: {-->
<!--                                style: {-->
<!--                                    colors: '#FEB019',-->
<!--                                },-->
<!--                            },-->
<!--                            title: {-->
<!--                                text: this.$t('expense'),-->
<!--                                style: {-->
<!--                                    color: '#FEB019',-->
<!--                                }-->
<!--                            }-->
<!--                        },-->
<!--                    ],-->
<!--                    tooltip: {-->
<!--                        fixed: {-->
<!--                            enabled: false,-->
<!--                            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft-->
<!--                            offsetY: 30,-->
<!--                            offsetX: 60-->
<!--                        },-->
<!--                    },-->
<!--                    legend: {-->
<!--                        horizontalAlign: 'left',-->
<!--                        offsetX: 10-->
<!--                    }-->
<!--                },-->
<!--            }-->
<!--        },-->
<!--        watch: {-->
<!--            dataProfitLoss: function (val) {-->
<!--                if (val){-->
<!--                    this.series[0].data = val.income;-->
<!--                    this.series[1].data = val.expenses;-->
<!--                    this.chartOptions.xaxis.categories = val.labels;-->

<!--                }-->

<!--            }-->
<!--        },-->
<!--        mounted() {-->
<!--        },-->
<!--    }-->

<!--</script>-->