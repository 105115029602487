<template>
    <div>
        <div class="row">
            <div class="col-md-5">
                <div class="border-secondary">
                    <div class="d-flex justify-content-between bg-light p-4">
                        <h6>{{$t('low_stock_products')}} <span class="badge badge-danger">{{lowStockItemCount}}</span></h6>
                        <router-link to="/items/items">
                            <span class="text-primary" style="line-height: 2;">{{$t('view_all')}}</span>
                        </router-link>
                    </div>
                    <div class="recent-invoices">
                        <div class="row-recent-invoices" v-for="(row, index) in data_list" :key="index">
                            <div class="d-flex justify-content-between">
                                <div class="w-100">
                                    <div class="d-flex justify-content-between">
                                        <p><b>{{row.name}}</b><span>#{{row.id}}</span></p>
                                        <h6>
                                            <span v-if="row.sum_available_qty == 0" class="badge badge-danger">{{$t('out_of_stock')}}</span>
                                            <span v-else class="badge badge-warning">{{$t('low_of_stock')}}</span>
                                        </h6>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <p><i class="fa fa-barcode"></i> {{row.barcode}}</p>
                                        <p><i class="fa fa-boxes"></i> {{row.sum_available_qty}} {{$t('available')}}</p>
                                    </div>
                                </div>
                                <div>
                                    <b-dropdown id="dropdown-offset" :text="''" variant="dark" class="m-2" size="sm">
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-7">
                <div class="border-secondary">
                    <div class="d-flex justify-content-between bg-light p-4">
                        <h6>{{$t('last_appointments')}} <span class="badge badge-danger">{{latestAppointmentsCount}}</span></h6>
                        <router-link to="/appointments">
                            <span class="text-primary" style="line-height: 2;">{{$t('view_all')}}</span>
                        </router-link>
                    </div>
                    <div class="recent-invoices">
                        <div class="row-recent-invoices" v-for="(row, index) in data_list_appointments" :key="index">
                            <div class="d-flex justify-content-between">
                                <div class="w-100">
                                    <div class="d-flex justify-content-between">
                                        <p class="mb-0">
                                            <b><i class="fa fa-calendar"></i> {{row.appintment_date}}</b>
                                            <span class="ml-4 mr-4"><i class="fa fa-clock"></i> {{row.start_at}} - {{row.interval}}</span>
                                        </p>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <p class="my-auto">{{row.customer? row.customer.name : ''}} #{{row.customer_id}}</p>
                                        <p class="m-3">
                                            <span class="d-block w-100">{{row.procedure_name}}</span>
                                            <span class="d-block w-100"><i class="fa fa-calendar"></i> {{row.status_name}}</span>

                                        </p>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <b-dropdown id="dropdown-offset" :text="''" variant="dark" class="m-2 my-auto" size="sm">
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </div>
                            <div><i class="fa fa-user"></i> {{ row.added_by}}</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </div>
</template>

<script>

    export default {
        name: "LowStockProductsLastAppointments",
        props: {
            products: null,
            lowStockItem: null,
            lowStockItemCount: null,
            latestAppointments: null,
            latestAppointmentsCount: null,
            currencyName: null,
        },
        data() {
            return {
                data_list: [],
                data_list_appointments: [],
            }
        },
        watch: {
            lowStockItem: function (val) {
                if (val) {
                    this.data_list = val;
                }
            },
            latestAppointments: function (val) {
                if (val) {
                    this.data_list_appointments = val;
                }
            },
        },

    }
</script>

<style scoped>
    .recent-invoices {
        height: 300px;
        overflow-y: scroll;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }

    .row-recent-invoices {
        padding: 15px;
        border-bottom: 1px solid #cccccc;
    }

</style>