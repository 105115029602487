<template>
    <div>
        <div class="recent-invoices">
            <div class="row-recent-invoices" v-for="(row, index) in data_list" :key="index">
                <div class="d-flex justify-content-between">
                    <div>
                        <p><b>#{{row.id}} - {{row.customer_fullname}}</b></p>
                        <p>{{$t('invoice')}} <b>#{{row.invoice.invoice_code}}</b> - {{row.payment_date}}</p>

                        <p class="mb-1">
                            <span class="mr-5"><i class="fa fa-user"></i> {{row.added_user_name}}</span>
                            <span><i class="fa fa-credit-card"></i> {{row.payment_method_name}} </span>
                        </p>
                    </div>
                    <div>
                        <div class="m-3">
                            <h4>{{row.amount}} {{currencyName }}</h4>
                            <h6><span class="badge badge-success">{{row.payment_status_name}}</span></h6>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        name: "RecentPayment",
        props:{
            recentPayment: null,
            currencyName: null,
        },
        data(){
            return{
                data_list: [],
            }
        },
        watch:{
            recentPayment: function (val) {
                if (val){
                    this.data_list = val;
                }
            }
        },

    }
</script>

<style scoped>
.recent-invoices{
    height: 300px;
    overflow-y: scroll;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.row-recent-invoices{
    padding: 15px;
    border-bottom: 1px solid #cccccc;
}
</style>