<template>
    <div>
        <div class="recent-invoices">
            <div class="row-recent-invoices" v-for="(row, index) in data_list" :key="index">
                <p>#{{row.invoice_code}} - {{row.invoice_date}}</p>
                <div class="d-flex justify-content-between">
                    <div>
                        <p><b>{{row.customer_name}}</b></p>
                        <p class="mb-1">{{row.customer? row.customer.city_name:'' }}</p>
                        <p class="mb-1">{{row.customer ? row.customer.address_1 :'' }} <span v-if="row.customer && row.customer.address_1 && row.customer.postal_code">,</span> {{row.customer.postal_code ? row.customer.postal_code :'' }}</p>
                        <p class="mb-1">{{row.customer.country_name }}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="m-3">
                            <h4>{{row.invoice_total}} {{currencyName }}</h4>
                            <h6><span class="badge badge-danger">{{row.payment_status}}</span></h6>
                        </div>
                        <div>
                            <b-dropdown id="dropdown-offset" :text="''"  variant="dark" class="m-2" size="sm">
                                <b-dropdown-item href="#">Action</b-dropdown-item>
                            </b-dropdown>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        name: "OverdueInvoices",
        props:{
            invoicesUnpaid: null,
            currencyName: null,
        },
        data(){
            return{
                data_list: [],
            }
        },
        watch:{
            invoicesUnpaid: function (val) {
                if (val){
                    this.data_list = val;
                }
            }
        },

    }
</script>

<style scoped>
.recent-invoices{
    height: 300px;
    overflow-y: scroll;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.row-recent-invoices{
    padding: 15px;
    border-bottom: 1px solid #cccccc;
}
</style>