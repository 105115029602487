<template>
    <div>

        <div>
            <!--begin::Row-->
            <div class="row m-0">
                <div class="col-md-2 mt-3" v-if="$can('pos_session.create')" >
                    <router-link to="/pos/pos-session/create" class="new-cards col bg-light-warning px-6 py-8 rounded-xl w-100 text-center d-flex my-auto justify-content-center">
                        <div class="text-warning font-weight-bold font-size-h3 text-center">
                            <div class="font-weight-bold text-light mt-5">{{$t('start_session')}}</div>
                        </div>
                    </router-link>

                </div>
                <div class="col-md-2 mt-3" v-if="$can('sales_invoices.create')">
                    <router-link to="/sales/sales_invoices/create" class="new-cards col bg-light-primary px-6 py-8 rounded-xl w-100 text-center d-flex my-auto justify-content-center">
                        <div class="text-primary font-weight-bold font-size-h3 text-center">
                            <div class="font-weight-bold text-light mt-5">{{$t('create_invoice')}}</div>
                        </div>
                    </router-link>
                </div>

                <div class="col-md-2 mt-3" v-if="$can('point_sales.list')">
                    <router-link to="/point-of-sales/point-of-sales" class="new-cards col bg-light-danger px-6 py-8 rounded-xl w-100 text-center d-flex my-auto justify-content-center">
                        <div class="text-danger font-weight-bold font-size-h3 text-center">
                            <div class="font-weight-bold text-light mt-5">{{$t('pos_session_n')}}</div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-2 mt-3" v-if="$can('sales_invoices.list')">
                    <router-link to="/sales/sales_invoices" class="new-cards col bg-light-success px-6 py-8 rounded-xl w-100 text-center d-flex my-auto justify-content-center">
                        <div class="text-success font-weight-bold font-size-h3 text-center">
                            <div class="font-weight-bold text-light mt-5">{{$t('invoices')}}</div>
                        </div>
                    </router-link>
                </div>

                <div class="col-md-2 mt-3" v-if="$can('customers.list')">
                    <router-link to="customers/customers" class="new-cards col bg-light-danger px-6 py-8 rounded-xl w-100 text-center d-flex my-auto justify-content-center">
                        <div class="text-danger font-weight-bold font-size-h3 text-center">
                            <div class="font-weight-bold text-light mt-5">{{$t('clients_n')}}</div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-2 mt-3" v-if="$can('customers.create')">
                    <router-link to="customers/customers/create" class="new-cards col bg-light-success px-6 py-8 rounded-xl w-100 text-center d-flex my-auto justify-content-center">
                        <div class="text-success font-weight-bold font-size-h3 text-center">
                            <div class="font-weight-bold text-light mt-5">{{$t('new_client')}}</div>
                        </div>
                    </router-link>
                </div>
            </div>
            <!--end::Row-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "QuickLinks"
    }
</script>

<style scoped>

</style>